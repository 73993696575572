import { isAfter } from 'date-fns';
import config from '../../config/config';
import { filterByMunicipalityOrNation } from '../../helpers/municipalityFilters';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { Entry } from '~/node_modules/contentful';
import { InDeBuurtCollection, InDeBuurtEntry, InDeBuurtType } from '~/types/InDeBuurt';
import { MunicipalityType } from '~/types/Municipality';
import { Site } from '~/types/RelEnvironment';

/* eslint-disable camelcase */
export default class InDeBuurt {
    protected type: string = config.contentful.types.inDeBuurt.id;

    /**
     * Get all available entries from contentful.
     */
    public getAll(type?: InDeBuurtType, site: Site = 'particulier'): Promise<InDeBuurtCollection | never> {
        const params = {
            content_type: this.type,
            order: '-fields.startTimestamp',
            limit: 1000,
            include: 6
        };
        if (type) {
            params['fields.type'] = type;
        }

        if (site === 'particulier') {
            params['fields.relDomain[nin]'] = 'zakelijk,vve';
        } else {
            params['fields.relDomain'] = site;
        }

        return getEntriesBulk<InDeBuurtEntry>(params).then(
            (entries): InDeBuurtCollection => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const items = entries.items.filter((entry: Entry<any>) => {
                    return !entry.fields.endTimestamp || isAfter(new Date(entry.fields.endTimestamp), new Date());
                });
                return <InDeBuurtCollection>{ ...entries, items };
            }
        );
    }

    /**
     * Get all available entries from contentful.
     * @param pageNumber
     * @param perPage
     * @param municipality
     * @param type
     */
    public async getByPage(
        pageNumber: number,
        perPage: number,
        type: InDeBuurtType,
        site: Site,
        municipality?: MunicipalityType
    ): Promise<InDeBuurtCollection | never> {
        const skipped = (pageNumber - 1) * perPage;

        let municipalityFilter = {};
        if (municipality && municipality.sys) {
            municipalityFilter = filterByMunicipalityOrNation(municipality, 'fields.municipalities');
        }

        const siteFilter =
            site === 'particulier' ? { 'fields.relDomain[nin]': 'zakelijk,vve' } : { 'fields.relDomain': site };

        const entries: InDeBuurtCollection = await contentfulClient.getEntries({
            ...municipalityFilter,
            ...siteFilter,
            content_type: this.type,
            'fields.type[in]': type,
            'fields.endTimestamp[gte]': new Date().toISOString(),
            order: 'fields.startTimestamp',
            skip: skipped,
            limit: perPage
        });

        if (entries.items.length < perPage) {
            const undated: InDeBuurtCollection = await contentfulClient.getEntries({
                ...municipalityFilter,
                ...siteFilter,
                content_type: this.type,
                'fields.type[in]': type,
                'fields.startTimestamp[exists]': false,
                order: '-sys.createdAt',
                skip: entries.items.length ? 0 : skipped,
                limit: perPage - entries.items.length
            });
            const items: Entry<InDeBuurtEntry>[] = [...entries.items, ...undated.items];
            entries.items = items;
            entries.total += undated.items.length;
        }

        return entries;
    }

    /**
     * Get a specific entry by its slug.
     * @param slug
     * @param type
     */
    public getBySlug(slug: string, type: InDeBuurtType): Promise<InDeBuurtEntry | never> {
        return contentfulClient
            .getEntries({
                limit: 1,
                content_type: this.type,
                'fields.slug': slug,
                'fields.type': type,
                include: 6
            })
            .then(
                (entries): InDeBuurtEntry => {
                    return <InDeBuurtEntry>entries.items[0];
                }
            );
    }
}
