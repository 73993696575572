import { EntryCollection } from 'contentful';

import config from '../../config/config';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';

export default class Municipality {
    protected type: string = config.contentful.types.municipality.id;

    /**
     * Get all available municipality entries from contentful.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAll(): Promise<EntryCollection<any> | never> {
        return (
            // prettier-ignore
            /* eslint-disable @typescript-eslint/no-explicit-any */
            getEntriesBulk<any>({ 'content_type': this.type, limit: 1000, include: 2 })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((entries): EntryCollection<any> => entries)
        );
    }

    /**
     * Get all municipality pages from contentful.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllPages(): Promise<EntryCollection<any> | never> {
        return (
            /* eslint-disable @typescript-eslint/no-explicit-any */
            getEntriesBulk<any>({
                // prettier-ignore
                'content_type': config.contentful.types.page.id,
                'fields.municipality[exists]': true,
                limit: 1000,
                include: 1
            })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((entries): EntryCollection<any> => entries)
        );
    }

    /**
     * Get a specific municipality by its slug.
     * @param slug
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getBySlug(slug: string): Promise<EntryCollection<any> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.slug': slug,
            // Include 3 levels, so we can retrieve province + country too
            include: 3
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getByApiId(id: string): Promise<EntryCollection<any> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.municipality': id,
            // Include 3 levels, so we can retrieve province + country too
            include: 3
        });
    }

    /**
     * Get a specific alert by its visibility on a page.
     * @param pageId
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getByPageId(pageId: string): Promise<EntryCollection<any> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.pagina.sys.id': pageId,
            // Include 3 levels, so we can retrieve province + country too
            include: 3
        });
    }
}
