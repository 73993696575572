import { EnvironmentConfig } from '../interfaces/config';
import ContentfulTypes from './ContentfulTypes';

let prefix = 'rel';

if (process.env.APP_TARGET === 'vve') {
    prefix = 'vve';
}

if (process.env.APP_TARGET === 'zakelijk') {
    prefix = 'zakelijk';
}

const config: EnvironmentConfig = {
    domain: `${prefix}-preview.netlify.app`,
    contentful: {
        spaceId: '2qwrovdxnzov',
        accessToken: '4c0b856712292e1bde6a92a2de10dffde7cbfe093b6574145c2d0a6a20ec3c25',
        environment: 'master',
        types: ContentfulTypes,
        nationId: 'm8bUyZzQivKPlq5KDGaJG',
        host: 'preview.contentful.com'
    },
    api: {
        baseURL: 'https://qa-api.regionaalenergieloket.nl/1.2',
        zipcodeURL: '/postcodes'
    },
    relApi: 'https://qa-rel-api.regionaalenergieloket.nl',
    cloudinary: {
        id: 'regionaalenergieloket'
    }
};

export default config;
