import config from '../../config/config';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { ProductSolutionEntry, ProductSolutionCollection } from '~/types/Product';

export default class ProductSolution {
    protected type: string = config.contentful.types.productSolution.id;

    /**
     * Get all available product solution entries from contentful.
     */
    public getAll(): Promise<ProductSolutionCollection | never> {
        return getEntriesBulk<ProductSolutionEntry>({
            // prettier-ignore
            'content_type': this.type,
            limit: 1000,
            include: 4
        }).then(
            (entries): ProductSolutionCollection => {
                return entries as ProductSolutionCollection;
            }
        );
    }

    /**
     * Get a specific product solution by its slug.
     * @param slug
     */
    public getBySlug(slug: string): Promise<ProductSolutionEntry | never> {
        return contentfulClient
            .getEntries({
                limit: 1,
                // prettier-ignore
                'content_type': this.type,
                'fields.slug': slug,
                include: 6
            })
            .then(
                (entries): ProductSolutionEntry => {
                    return <ProductSolutionEntry>entries.items[0];
                }
            );
    }
}
