import { EnvironmentConfig } from '../interfaces/config';
import ContentfulTypes from './ContentfulTypes';

let prefix = '';

if (process.env.APP_TARGET === 'vve') {
    prefix = '-vve';
}

if (process.env.APP_TARGET === 'zakelijk') {
    prefix = '-zakelijk';
}

const config: EnvironmentConfig = {
    domain: `staging${prefix}.regionaalenergieloket.nl`,
    contentful: {
        spaceId: '2qwrovdxnzov',
        accessToken: '45d1da6952b63467be51a24a08e968934330bf0f554014b7426e39cbc8887052',
        environment: 'staging',
        types: ContentfulTypes,
        nationId: 'm8bUyZzQivKPlq5KDGaJG'
    },
    api: {
        baseURL: 'https://qa-api.regionaalenergieloket.nl/1.2',
        zipcodeURL: '/postcodes'
    },
    relApi: 'https://qa-rel-api.regionaalenergieloket.nl',
    cloudinary: {
        id: 'regionaalenergieloket'
    }
};

export default config;
