import { Entry, EntryCollection } from 'contentful';
import contentfulClient from './client';

export async function getEntriesBulk<T>(options: {}): Promise<EntryCollection<T>> {
    const totalAmount: EntryCollection<T> = await contentfulClient.getEntries({
        ...options,
        limit: 1,
        include: 0
    });

    const loopAmount = Math.ceil(totalAmount.total / 100);

    const promises: Promise<EntryCollection<T>>[] = [];

    for (let a = 0; a < loopAmount; a++) {
        promises.push(
            contentfulClient.getEntries({
                ...options,
                limit: 100,
                skip: a * 100
            })
        );
    }

    const responses: EntryCollection<T>[] = await Promise.all(promises);

    const emptyArr: Entry<T>[] = [];

    const entryCollection = totalAmount;
    entryCollection.items = emptyArr.concat.apply(
        [],
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        responses.map(response => {
            return response.items;
        })
    );
    return entryCollection;
}
