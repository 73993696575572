import { EnvironmentConfig } from '../interfaces/config';
import ContentfulTypes from './ContentfulTypes';

let subdomain = '';

if (process.env.APP_TARGET === 'vve') {
    subdomain = 'vve.';
}

if (process.env.APP_TARGET === 'zakelijk') {
    subdomain = 'zakelijk.';
}

const config: EnvironmentConfig = {
    domain: `${subdomain}regionaalenergieloket.nl`,
    contentful: {
        spaceId: '2qwrovdxnzov',
        accessToken: '45d1da6952b63467be51a24a08e968934330bf0f554014b7426e39cbc8887052',
        environment: 'master',
        types: ContentfulTypes,
        nationId: 'm8bUyZzQivKPlq5KDGaJG'
    },
    api: {
        baseURL: 'https://api.regionaalenergieloket.nl/1.2',
        zipcodeURL: '/postcodes'
    },
    relApi: 'https://rel-api.regionaalenergieloket.nl',
    cloudinary: {
        id: 'regionaalenergieloket'
    }
};

export default config;
