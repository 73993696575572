import {
    Config as ConfigInterface,
    EnvironmentConfig,
    GlobalConfig as GlobalConfigInterface
} from '../interfaces/config';

import production from './production';
import staging from './staging';
import development from './development';
import preview from './preview';

export const env: string = process.env.APP_ENV || 'development';

const globalConfig: GlobalConfigInterface = {
    offerUrl: 'https://offerte.regionaalenergieloket.nl',
    dataLake: 'https://rel-api.regionaalenergieloket.nl/1.0/REL/',
    gaKey: 'UA-60482777-9',
    gaAggKey: 'UA-60482777-8',
    // The domains listed below will receive the "allow-same-origin" flag when used in an iframe.
    trustedIframeDomains: [
        'greenhome.nl',
        'huisscan.regionaalenergieloket.nl',
        'www.greenhome.nl',
        'redirect.regionaalenergieloket.nl',
        'offerte.regionaalenergieloket.nl',
        'www.geosheets.com'
    ],
    redirectDomain: 'redirect.regionaalenergieloket.nl'
};

const envConfig: object = {
    production,
    staging,
    development,
    preview
};

const usedConfig: EnvironmentConfig = envConfig[env];
const config: ConfigInterface = Object.assign(globalConfig, usedConfig);

export default config;
