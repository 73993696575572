import { EntryCollection } from 'contentful';
import { Site } from '../../types/RelEnvironment';

import config from '../../config/config';
import contentfulClient from './client';

export default class Footer {
    protected type: string = config.contentful.types.footer.id;

    /**
     * Get all available page entries from contentful.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAll(site: Site = 'particulier'): Promise<EntryCollection<any> | never> {
        // prettier-ignore
        return contentfulClient.getEntries({ 'content_type': this.type, 'fields.relDomain': site })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((entries): EntryCollection<any> => {
                return entries;
            });
    }

    /**
     * Get a specific footer by its name.
     * @param name
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getByName(name: string, site: Site = 'particulier'): Promise<EntryCollection<any> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            'fields.name': name
        });
    }
}
