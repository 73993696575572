import { filterByMunicipalityProvinceCountry } from '../../helpers/municipalityFilters';
import { contentfulParamsFromFilterDefinition } from '../../helpers/store/filters';
import config from '../../config/config';
import { Site } from '../../types/RelEnvironment';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { FinancialBenefitCollection, FinancialBenefitEntry, FinancialBenefitTypeEntry } from '~/types/FinancialBenefit';
import { MunicipalityType } from '~/types/Municipality';
import { FilterDefinition } from '~/types/FilterDefinition';

export default class FinancialBenefit {
    protected type: string = config.contentful.types.financialBenefit.id;

    /**
     * Get all available page entries from contentful.
     */
    public getAll(site: Site = 'particulier'): Promise<FinancialBenefitCollection | never> {
        const options = {
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            order: '-fields.priority,fields.type,fields.title'
        };
        return getEntriesBulk<FinancialBenefitEntry>(options).then(
            (entries): FinancialBenefitCollection => <FinancialBenefitCollection>entries
        );
    }

    /**
     * Get all available page entries from contentful of a specific type
     */
    public getByType(
        type: FinancialBenefitTypeEntry,
        site: Site = 'particulier'
    ): Promise<FinancialBenefitCollection | never> {
        const options = {
            // prettier-ignore
            'content_type': this.type,
            'fields.type': type,
            'fields.relDomain': site,
            order: '-fields.priority,fields.title'
        };
        return getEntriesBulk<FinancialBenefitEntry>(options).then(
            (entries): FinancialBenefitCollection => <FinancialBenefitCollection>entries
        );
    }

    /**
     * Get all available project entries from contentful paginated
     * @param pageNumber
     * @param perPage
     * @param municipality
     */
    public getByPage(
        pageNumber: number,
        perPage: number,
        site: Site = 'particulier',
        municipality?: MunicipalityType
    ): Promise<FinancialBenefitCollection | never> {
        let municipalityFilter = {};

        if (municipality && municipality.sys) {
            municipalityFilter = filterByMunicipalityProvinceCountry(municipality);
        }

        const options = {
            ...municipalityFilter,
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            order: '-fields.priority,fields.type,fields.title',
            skip: (pageNumber - 1) * perPage,
            limit: perPage
        };

        return contentfulClient
            .getEntries(options)
            .then((entries): FinancialBenefitCollection => entries as FinancialBenefitCollection);
    }

    /**
     * Get a paginated and filtered list of financial benefits from contentful
     * @param pageNumber
     * @param perPage
     * @param municipality
     */
    public getFilteredByPage(
        filters: FilterDefinition[],
        { pageNumber, perPage }: { pageNumber: number; perPage: number },
        site: Site = 'particulier',
        municipality?: MunicipalityType
    ): Promise<FinancialBenefitCollection | never> {
        const filterParams = contentfulParamsFromFilterDefinition(filters);
        let municipalityFilter = {};

        if (municipality && municipality.sys) {
            municipalityFilter = filterByMunicipalityProvinceCountry(municipality);
        }

        const options = {
            ...municipalityFilter,
            ...filterParams,
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            order: '-fields.priority,fields.type,fields.title',
            skip: (pageNumber - 1) * perPage,
            limit: perPage
        };

        return contentfulClient
            .getEntries(options)
            .then((entries): FinancialBenefitCollection => entries as FinancialBenefitCollection);
    }
}
