import { EntryCollection } from 'contentful';
import config from '../../config/config';
import { filterByMunicipalityProvinceCountry } from '../../helpers/municipalityFilters';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { ArticleFields } from '~/types/Article';
import { MunicipalityType } from '~/types/Municipality';
import { TagType } from '~/types/Tag';

export default class Article {
    protected type: string = config.contentful.types.article.id;

    /**
     * Get articles
     */
    public get(municipality?: MunicipalityType, tags?: TagType[]): Promise<EntryCollection<ArticleFields> | never> {
        const now = new Date();

        const municipalityFilter = filterByMunicipalityProvinceCountry(municipality, 'fields.municipalities');
        let tagFilter = {};

        if (tags && tags.length > 0) {
            const tagList = tags.map((tag): string => tag.sys.id);
            tagFilter = {
                'fields.tags.sys.id[in]': tagList.join(',')
            };
        }

        const params = {
            ...municipalityFilter,
            ...tagFilter,
            limit: 9,
            content_type: this.type,
            'fields.publishDate[lte]': now,
            order: '-fields.publishDate',
            include: 3
        };

        return getEntriesBulk<ArticleFields>(params).then(
            (entries): EntryCollection<ArticleFields> => {
                return <EntryCollection<ArticleFields>>entries;
            }
        );
    }

    /*
     * Get all available page entries from contentful.
     */
    public getAll(): Promise<EntryCollection<ArticleFields> | never> {
        return getEntriesBulk<ArticleFields>({
            /* eslint-disable camelcase */
            content_type: this.type,
            limit: 1000,
            include: 3
        }).then(
            (entries): EntryCollection<ArticleFields> => {
                return <EntryCollection<ArticleFields>>entries;
            }
        );
    }

    /**
     * Get a specific page by its slug.
     * @param slug
     */
    public getBySlug(slug: string): Promise<EntryCollection<ArticleFields> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            /* eslint-disable camelcase */
            content_type: this.type,
            'fields.slug': slug,
            include: 6
        });
    }
}
