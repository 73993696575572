import { EntryCollection } from 'contentful';

import config from '../../config/config';
import { Site } from '../../types/RelEnvironment';
import contentfulClient from './client';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class Page {
    protected type: string = config.contentful.types.page.id;

    /**
     * Get all available page entries from contentful.
     */
    public async getAll(site: Site = 'particulier'): Promise<EntryCollection<any> | never> {
        let page = 1;
        const perPage = 50;
        const entries = await this.getByPage(page, perPage, site);
        const requests = Math.ceil(entries.total / perPage);
        const promises: Promise<EntryCollection<any>>[] = [];
        while (page < requests) {
            page++;
            promises.push(this.getByPage(page, perPage, site));
        }
        const responses: EntryCollection<any>[] = await Promise.all(promises);
        responses.forEach((chunk: EntryCollection<any>): void => {
            entries.items = [...entries.items, ...chunk.items];
        });
        return entries;
    }

    /**
     * Get a paginated list of page entries from contentful.
     * @param pageNumber
     * @param perPage
     */
    public getByPage(
        pageNumber: number,
        perPage: number,
        site: Site = 'particulier'
    ): Promise<EntryCollection<any> | never> {
        const options = {
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            skip: (pageNumber - 1) * perPage,
            limit: perPage,
            include: 4
        };

        return contentfulClient
            .getEntries(options)
            .then((entries): EntryCollection<any> => entries as EntryCollection<any>);
    }

    /**
     * Get a specific page by its slug.
     * @param slug
     */
    public getBySlug(slug: string, site: Site = 'particulier'): Promise<EntryCollection<any> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.slug': slug,
            'fields.relDomain': site,
            include: 6,
            order: 'sys.createdAt'
        });
    }

    /**
     * Get a specific page by its municipality.
     * @param municipalityId the municipality as obtained from GreenHome API
     */
    public getByMunicipalityId(
        municipalityId: string,
        site: Site = 'particulier'
    ): Promise<EntryCollection<any> | never> {
        const options = {
            limit: 1,
            // prettier-ignore
            'content_type': this.type,
            'fields.relDomain': site,
            'fields.municipality.fields.municipality': municipalityId,
            'fields.municipality.sys.contentType.sys.id': config.contentful.types.municipality.id,
            include: 4
        };
        return contentfulClient.getEntries(options);
    }
}
