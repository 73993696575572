import { ContentfulTypesInterface } from '~/interfaces/ContentfulTypesInterface';

const types: ContentfulTypesInterface = {
    page: {
        id: 'page',
        slug: ''
    },
    municipality: {
        id: 'municipality',
        slug: ''
    },
    footer: {
        id: 'footer',
        slug: ''
    },
    alert: {
        id: 'alert',
        slug: ''
    },
    banner: {
        id: 'municipalityBanner',
        slug: ''
    },
    tag: {
        id: 'tags',
        slug: ''
    },
    article: {
        id: 'article',
        slug: 'artikelen'
    },
    productSolution: {
        id: 'productSolution',
        slug: 'energiebesparen'
    },
    productIntention: {
        id: 'productIntention',
        slug: ''
    },
    inDeBuurt: {
        id: 'inDeBuurt',
        slug: 'indebuurt'
    },
    eventCollection: {
        id: 'evenementProjectLokaalInitiatiefOverzicht',
        slug: ''
    },
    financialBenefit: {
        id: 'financialBenefit',
        slug: ''
    },
    financialBenefitType: {
        id: 'financieelVoordeelType',
        slug: ''
    },
    financialBenefitOverview: {
        id: 'financialBenefitOverview',
        slug: ''
    },
    experiencesOverview: {
        id: 'experiencesOverview',
        slug: ''
    },
    experience: {
        id: 'experiences',
        slug: 'ervaringen'
    },
    supplier: {
        id: 'supplier',
        slug: 'vakspecialisten'
    },
    supplierOverview: {
        id: 'supplierOverview',
        slug: ''
    },
    supplierReview: {
        id: 'supplierReview',
        slug: ''
    }
};

export default types;
