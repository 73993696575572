const middleware = {}

middleware['municipalityRedirect'] = require('../middleware/municipalityRedirect.ts')
middleware['municipalityRedirect'] = middleware['municipalityRedirect'].default || middleware['municipalityRedirect']

middleware['regexRedirect'] = require('../middleware/regexRedirect.ts')
middleware['regexRedirect'] = middleware['regexRedirect'].default || middleware['regexRedirect']

middleware['setHomepageSlug'] = require('../middleware/setHomepageSlug.ts')
middleware['setHomepageSlug'] = middleware['setHomepageSlug'].default || middleware['setHomepageSlug']

export default middleware
