import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b80fe7e = () => interopDefault(import('../pages/acties/index.vue' /* webpackChunkName: "pages/acties/index" */))
const _6f237701 = () => interopDefault(import('../pages/energiebesparen/index.vue' /* webpackChunkName: "pages/energiebesparen/index" */))
const _d608dda6 = () => interopDefault(import('../pages/ervaringen/index.vue' /* webpackChunkName: "pages/ervaringen/index" */))
const _3e67c7a7 = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _3d2ff23a = () => interopDefault(import('../pages/subsidies/index.vue' /* webpackChunkName: "pages/subsidies/index" */))
const _216f2194 = () => interopDefault(import('../pages/vakspecialisten/index.vue' /* webpackChunkName: "pages/vakspecialisten/index" */))
const _274a87b6 = () => interopDefault(import('../pages/indebuurt/evenementen/_slug/index.vue' /* webpackChunkName: "pages/indebuurt/evenementen/_slug/index" */))
const _1d039b82 = () => interopDefault(import('../pages/indebuurt/initiatieven/_slug/index.vue' /* webpackChunkName: "pages/indebuurt/initiatieven/_slug/index" */))
const _e9d2d034 = () => interopDefault(import('../pages/indebuurt/projecten/_slug/index.vue' /* webpackChunkName: "pages/indebuurt/projecten/_slug/index" */))
const _14df022a = () => interopDefault(import('../pages/artikelen/_slug/index.vue' /* webpackChunkName: "pages/artikelen/_slug/index" */))
const _ec332508 = () => interopDefault(import('../pages/energiebesparen/_slug/index.vue' /* webpackChunkName: "pages/energiebesparen/_slug/index" */))
const _675536a8 = () => interopDefault(import('../pages/ervaringen/_slug/index.vue' /* webpackChunkName: "pages/ervaringen/_slug/index" */))
const _38ab7486 = () => interopDefault(import('../pages/sectoren/_slug/index.vue' /* webpackChunkName: "pages/sectoren/_slug/index" */))
const _5b7e9dcf = () => interopDefault(import('../pages/vakspecialisten/_slug/index.vue' /* webpackChunkName: "pages/vakspecialisten/_slug/index" */))
const _f9db31b8 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acties/",
    component: _3b80fe7e,
    pathToRegexpOptions: {"strict":true},
    name: "acties"
  }, {
    path: "/energiebesparen/",
    component: _6f237701,
    pathToRegexpOptions: {"strict":true},
    name: "energiebesparen"
  }, {
    path: "/ervaringen/",
    component: _d608dda6,
    pathToRegexpOptions: {"strict":true},
    name: "ervaringen"
  }, {
    path: "/styleguide/",
    component: _3e67c7a7,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide"
  }, {
    path: "/subsidies/",
    component: _3d2ff23a,
    pathToRegexpOptions: {"strict":true},
    name: "subsidies"
  }, {
    path: "/vakspecialisten/",
    component: _216f2194,
    pathToRegexpOptions: {"strict":true},
    name: "vakspecialisten"
  }, {
    path: "/indebuurt/evenementen/:slug/",
    component: _274a87b6,
    pathToRegexpOptions: {"strict":true},
    name: "indebuurt-evenementen-slug"
  }, {
    path: "/indebuurt/initiatieven/:slug/",
    component: _1d039b82,
    pathToRegexpOptions: {"strict":true},
    name: "indebuurt-initiatieven-slug"
  }, {
    path: "/indebuurt/projecten/:slug/",
    component: _e9d2d034,
    pathToRegexpOptions: {"strict":true},
    name: "indebuurt-projecten-slug"
  }, {
    path: "/artikelen/:slug/",
    component: _14df022a,
    pathToRegexpOptions: {"strict":true},
    name: "artikelen-slug"
  }, {
    path: "/energiebesparen/:slug/",
    component: _ec332508,
    pathToRegexpOptions: {"strict":true},
    name: "energiebesparen-slug"
  }, {
    path: "/ervaringen/:slug/",
    component: _675536a8,
    pathToRegexpOptions: {"strict":true},
    name: "ervaringen-slug"
  }, {
    path: "/sectoren/:slug/",
    component: _38ab7486,
    pathToRegexpOptions: {"strict":true},
    name: "sectoren-slug"
  }, {
    path: "/vakspecialisten/:slug/",
    component: _5b7e9dcf,
    pathToRegexpOptions: {"strict":true},
    name: "vakspecialisten-slug"
  }, {
    path: "/:slug/",
    component: _f9db31b8,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }, {
    path: "/",
    component: _f9db31b8,
    name: "landing-page"
  }, {
    path: "/:municipality/artikelen/:slug",
    component: _14df022a,
    name: "gemeente-artikelen-slug"
  }, {
    path: "/:municipality/energiebesparen",
    component: _6f237701,
    name: "gemeente-energiebesparen"
  }, {
    path: "/:municipality/energiebesparen/:slug",
    component: _ec332508,
    name: "gemeente-energiebesparen-slug"
  }, {
    path: "/:municipality/ervaringen",
    component: _d608dda6,
    name: "gemeente-ervaringen"
  }, {
    path: "/:municipality/ervaringen/:slug",
    component: _675536a8,
    name: "gemeente-ervaringen-slug"
  }, {
    path: "/:municipality/indebuurt/evenementen/:slug",
    component: _274a87b6,
    name: "gemeente-indebuurt-evenementen-slug"
  }, {
    path: "/:municipality/indebuurt/initiatieven/:slug",
    component: _1d039b82,
    name: "gemeente-indebuurt-initiatieven-slug"
  }, {
    path: "/:municipality/indebuurt/projecten/:slug",
    component: _e9d2d034,
    name: "gemeente-indebuurt-projecten-slug"
  }, {
    path: "/:municipality/sectoren/:slug",
    component: _38ab7486,
    name: "gemeente-sectoren"
  }, {
    path: "/:municipality/subsidies",
    component: _3d2ff23a,
    name: "gemeente-subsidies"
  }, {
    path: "/:municipality/acties",
    component: _3b80fe7e,
    name: "gemeente-acties"
  }, {
    path: "/:municipality/vakspecialisten",
    component: _216f2194,
    name: "gemeente-vakspecialisten"
  }, {
    path: "/:municipality/vakspecialisten/:slug",
    component: _5b7e9dcf,
    name: "gemeente-vakspecialisten-slug"
  }, {
    path: "/:municipality/:slug",
    component: _f9db31b8,
    name: "gemeente-slug"
  }, {
    path: "/:slug",
    redirect: "/:slug/"
  }, {
    path: "/indebuurt/event/:slug",
    redirect: "/indebuurt/evenementen/:slug"
  }, {
    path: "/indebuurt/initiative/:slug",
    redirect: "/indebuurt/initiatieven/:slug"
  }, {
    path: "/indebuurt/project/:slug",
    redirect: "/indebuurt/projecten/:slug"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
