import { createClient } from 'contentful';
import config from '../../config/config';

// Export the configured client.
export default createClient({
    host: config.contentful.host || 'cdn.contentful.com',
    space: config.contentful.spaceId,
    accessToken: config.contentful.accessToken,
    environment: config.contentful.environment
});
