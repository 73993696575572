import { EntryCollection } from 'contentful';

import config from '../../config/config';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { AlertEntry } from '~/types/Alert';
import { PageProperties } from '~/types/Page';

export default class Alert {
    protected type: string = config.contentful.types.alert.id;

    /**
     * Get all available page entries from contentful.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAll(): Promise<EntryCollection<any> | never> {
        return (
            /* eslint-disable @typescript-eslint/no-explicit-any */
            getEntriesBulk<any>({
                // prettier-ignore
                'content_type': this.type
            })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then(
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    (entries): EntryCollection<any> => {
                        return entries;
                    }
                )
        );
    }

    /**
     * Get a specific alert by its visibility on a page.
     * @param isPublic
     * @param currentPageId
     * @param municipalityId
     * @param zipcode
     */
    public async getByVisibility(
        isPublic: boolean,
        currentPageId: string,
        municipalityId?: string,
        zipcode?: string
    ): Promise<AlertEntry[] | never> {
        const now = new Date().toISOString();

        const promises: Promise<AlertEntry[]>[] = [];
        const baseQuery = {
            limit: 10,
            // prettier-ignore
            'content_type': this.type,
            'fields.startDate[lte]': now,
            'fields.expirationDate[gt]': now,
            order: '-fields.priority,-fields.startDate'
        };

        // Fetch generic entries, if on a municipality page
        const page = await contentfulClient.getEntry(currentPageId);
        const fields = page.fields as PageProperties;
        
        if (isPublic && fields && fields.municipality) {
            promises.push(
                /* eslint-disable @typescript-eslint/no-explicit-any */
                getEntriesBulk<any>({
                    ...baseQuery,
                    'fields.municipalities[exists]': 'false',
                    'fields.zipcodes[exists]': 'false'
                }).then((entries): AlertEntry[] => {
                    return entries.items as AlertEntry[];
                })
            );
        }

        // Fetch generic entries without a zipcode or municipality for the current page
        baseQuery['fields.visiblePages.sys.id'] = currentPageId;

        promises.push(
            /* eslint-disable @typescript-eslint/no-explicit-any */
            getEntriesBulk<any>({
                ...baseQuery,
                'fields.municipalities[exists]': 'false',
                'fields.zipcodes[exists]': 'false'
            }).then((entries): AlertEntry[] => {
                return entries.items as AlertEntry[];
            })
        );

        // Fetch entries matching on zipcode
        if (zipcode) {
            promises.push(
                /* eslint-disable @typescript-eslint/no-explicit-any */
                getEntriesBulk<any>({
                    ...baseQuery,
                    'fields.zipcodes[match]': zipcode
                }).then((entries): AlertEntry[] => {
                    return entries.items as AlertEntry[];
                })
            );

            // Allow zipcodes with spaces
            promises.push(
                contentfulClient
                    .getEntries({
                        ...baseQuery,
                        'fields.zipcodes[match]': `${zipcode.substring(0, 4)} ${zipcode.substring(4)}`
                    })
                    .then((entries): AlertEntry[] => {
                        return entries.items as AlertEntry[];
                    })
            );
        }

        // Fetch entries matching on municipality
        if (municipalityId) {
            promises.push(
                /* eslint-disable @typescript-eslint/no-explicit-any */
                getEntriesBulk<any>({
                    ...baseQuery,
                    'fields.municipalities.sys.id': municipalityId
                }).then((entries): AlertEntry[] => {
                    return entries.items as AlertEntry[];
                })
            );
        }

        // Execute the calls and resolve them.
        return Promise.all(promises).then((results): AlertEntry[] => {
            let allAlerts: AlertEntry[] = [];
            for (const alerts of results) {
                allAlerts = allAlerts.concat(alerts);
            }
            return allAlerts;
        });
    }
}
