import { EntryCollection } from 'contentful';

import config from '../../config/config';
import { getEntriesBulk } from './genericBulk';
import contentfulClient from './client';
import { MunicipalityBannerEntry } from '~/types/MunicipalityBanner';

export default class MunicipalityBanner {
    protected type: string = config.contentful.types.banner.id;

    /**
     * Get all available banner entries from contentful.
     */
    public getAll(): Promise<EntryCollection<MunicipalityBannerEntry> | never> {
        // eslint-disable-next-line camelcase
        return getEntriesBulk<MunicipalityBannerEntry>({ content_type: this.type });
    }

    /**
     * Get a specific banner by its name.
     * @param name
     */
    public getByName(name: string): Promise<EntryCollection<MunicipalityBannerEntry> | never> {
        return contentfulClient.getEntries({
            limit: 1,
            // eslint-disable-next-line camelcase
            content_type: this.type,
            'fields.name': name
        });
    }
}
