import config from '../../config/config';
import { contentfulParamsFromFilterDefinition } from '../../helpers/store/filters';
import contentfulClient from './client';
import { getEntriesBulk } from './genericBulk';
import { ExperienceCollection, ExperienceEntry } from '~/types/Experience';
import { ProductSolutionEntry } from '~/types/Product';
import { FilterDefinition } from '~/types/FilterDefinition';

export default class Experience {
    protected type: string = config.contentful.types.experience.id;

    /**
     * Get all available page entries from contentful.
     */
    public getAll(): Promise<ExperienceCollection | never> {
        return getEntriesBulk<ExperienceEntry>({
            // prettier-ignore
            'content_type': this.type,
            order: '-sys.createdAt',
            limit: 1000,
            include: 4
        }).then(
            (entries): ExperienceCollection => {
                return <ExperienceCollection>entries;
            }
        );
    }

    /**
     * Get a paginated list of experience entries from contentful.
     * @param pageNumber
     * @param perPage
     */
    public getByPage(pageNumber: number, perPage: number): Promise<ExperienceCollection | never> {
        const skipped = (pageNumber - 1) * perPage;
        return contentfulClient
            .getEntries({
                // prettier-ignore
                'content_type': this.type,
                order: '-sys.createdAt',
                skip: skipped,
                limit: perPage
            })
            .then(
                (entries): ExperienceCollection => {
                    return entries as ExperienceCollection;
                }
            );
    }

    /**
     * Get a paginated and filtered list of experience entries from contentful.
     * @param filters
     * @param pageNumber
     * @param perPage
     */
    public getFilteredByPage(
        filters: FilterDefinition[],
        { pageNumber, perPage }: { pageNumber: number; perPage: number }
    ): Promise<ExperienceCollection | never> {
        const skipped = (pageNumber - 1) * perPage;
        const filterParams = contentfulParamsFromFilterDefinition(filters);

        return contentfulClient
            .getEntries({
                ...filterParams,
                // prettier-ignore
                'content_type': this.type,
                order: '-sys.createdAt',
                skip: skipped,
                limit: perPage
            })
            .then(
                (entries): ExperienceCollection => {
                    return entries as ExperienceCollection;
                }
            );
    }

    /**
     * Get a specific experience by its slug.
     * @param slug
     */
    public getBySlug(slug: string): Promise<ExperienceEntry | never> {
        return contentfulClient
            .getEntries({
                limit: 1,
                // prettier-ignore
                'content_type': this.type,
                'fields.slug': slug,
                include: 6
            })
            .then(
                (entries): ExperienceEntry => {
                    return <ExperienceEntry>entries.items[0];
                }
            );
    }

    /**
     * Get a list of experience entries that are linked to either of the Product Solutions provided.
     * @param productSolutions
     * @param excludeId the id of the experience to exclude from these results.
     */
    public getByProductSolutions(
        productSolutions: ProductSolutionEntry[],
        excludeId: string
    ): Promise<ExperienceCollection | never> {
        let productSolutionFilter = {};
        if (productSolutions) {
            const productSolutionIds = productSolutions.map((solution): string => solution.sys.id);
            productSolutionFilter = {
                'fields.appliedSolutions.sys.id[in]': productSolutionIds.join(',')
            };
        }
        return getEntriesBulk<ExperienceEntry>({
            ...productSolutionFilter,
            'sys.id[ne]': excludeId,
            // prettier-ignore
            'content_type': this.type,
            order: '-sys.createdAt',
            limit: 3
        }).then(
            (entries): ExperienceCollection => {
                return entries as ExperienceCollection;
            }
        );
    }
}
