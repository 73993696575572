import axios from 'axios';
import { EntryCollection } from 'contentful';
import { FilterDefinition } from '../../types/FilterDefinition';
import { MunicipalityType } from '../../types/Municipality';
import { Location } from '../../store/location';
import config from '../../config/config';
import { Site } from '../../types/RelEnvironment';
import { supplierParamsFromFilterDefinition } from '../../helpers/store/filters';
import { SupplierEntry, SupplierFields } from '~/types/Supplier';

export default class Supplier {
    protected type: string = config.contentful.types.supplier.id;

    private generateUserCoordinates(userLocation: Location, municipality?: MunicipalityType): [number, number] {
        let latitude = 0;
        let longitude = 0;

        if (userLocation && userLocation.geolocation) {
            latitude = userLocation.geolocation.latitude;
            longitude = userLocation.geolocation.longitunde;
        } else if (municipality && municipality.fields.address.fields.address) {
            latitude = Number(municipality.fields.address.fields.address.lat);
            longitude = Number(municipality.fields.address.fields.address.lon);
        }

        return [latitude, longitude];
    }

    public async getFilteredByPage(
        relDomain: Site,
        filters: FilterDefinition[],
        { pageNumber, perPage }: { pageNumber: number; perPage: number },
        userLocation: Location,
        municipality?: MunicipalityType
    ): Promise<{ total: number; items: SupplierEntry[] } | {}> {
        const filterParams: object = supplierParamsFromFilterDefinition(filters);

        const [latitude, longitude] = this.generateUserCoordinates(userLocation, municipality);

        const showActiveInAreaOnly = !!filterParams['Show active in area only'];
        const productOptions = filterParams['Maatregel(en)']
            ? filterParams['Maatregel(en)'].map((option): string => option.value).toString()
            : '';

        const averageRating = filterParams['Minimale beoordeling'] ? filterParams['Minimale beoordeling'][0].value : 0;
        const showWithinRadius =
            filterParams['Show within radius'] && filterParams['Show within radius'][0].value < 100
                ? filterParams['Show within radius'][0].value
                : 0;

        let result: EntryCollection<SupplierFields> | {};
        const url =
            `${config.relApi}/1.0/supplier/${latitude}/${longitude}/${showActiveInAreaOnly}` +
            `/options=${productOptions}/${averageRating}/${showWithinRadius}/${relDomain}/${perPage}/${pageNumber - 1}`;

        try {
            const response = await axios.get(url);

            result = response.data;
        } catch (error) {
            result = {};
        }

        return result;
    }

    public static async getDistanceFromUser(
        lat: number,
        lon: number,
        supplierId: string
    ): Promise<{ distance: number; workRadius: number }> {
        const result = await axios.get(`${config.relApi}/1.0/supplier/distanceFromClient/${lat}/${lon}/${supplierId}`);
        return result.data;
    }

    public async getBySlug(slug: string): Promise<SupplierEntry | never> {
        const result = await axios.get(`${config.relApi}/1.0/supplier/slug/${slug}`);
        return result.data;
    }

    public async getAll(relDomain: Site = 'particulier'): Promise<EntryCollection<SupplierEntry>> {
        const response = await axios.get(`${config.relApi}/1.0/supplier/0/0/false/options=/0/0/${relDomain}/100000/0/`);
        return response.data;
    }
}
